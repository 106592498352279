import React from 'react';
import { AcceptIcon, RejectIcon, DeleteIcon, UserRequestIcon }  from '../../Utils/Icons';

const userRequests = [
    {
        name: 'MAria Alejandra Muñoz alarocn 120355',
        email: 'alejandra161907@gmail.com',
        institution: 'Universidad Nacional de Colombia',
        rol: 'Analista',
        userRequest: 'Tomador de Decisiones',
        justification: "Hola, quiero hacer esta solicitud porque quiero estar aquí y es muy lindo para todos estar aquí. Por favor me aceptan, gracias de una vez les digo que muchas gracias por todo, de antemano les agradezco.",
    },
    {
        name: 'Norita Adriana Montealegre Hernández',
        email: 'correo1@gmail.com',
        institution: 'Universidad Nacional de Colombia',
        rol: 'Director general de gerencia',
        userRequest: 'Tomador de Decisiones',
        justification: "Hola, quiero hacer esta solicitud porque quiero estar aquí y es muy lindo para todos estar aquí. Por favor me aceptan, gracias de una vez les digo que muchas gracias por todo, de antemano les agradezco.",
    },
    {
        name: 'Dayana Jimenez',
        email: 'correo2@gmail.com',
        institution: 'Universidad Nacional de Colombia',
        rol: 'Analista',
        userRequest: 'Tomador de Decisiones',
        justification: "Hola, quiero hacer esta solicitud porque quiero estar aquí y es muy lindo para todos estar aquí. Por favor me aceptan, gracias de una vez les digo que muchas gracias por todo, de antemano les agradezco.",
    },
];

const UserRequestsPague: React.FC = () => {
    return (
        <div className="overflow-x-auto m-4 min-h-screen">
            <table className="min-w-full border border-primary text-sm text-left">
                <thead className="bg-primary text-textColor">
                    <tr>
                        <th className="px-2 py-2 border-r border-gris2">Nombre Completo</th>
                        <th className="px-2 py-2 border-r border-gris2">Correo</th>
                        <th className="px-2 py-2 border-r border-gris2">Institución</th>
                        <th className="px-2 py-2 border-r border-gris2">Cargo</th>
                        <th className="px-2 py-2 border-r border-gris2">Usuario Solicitado</th>
                        <th className="px-2 py-2 border-r border-gris2 w-1/4">Justificación</th>
                        <th className="px-2 py-2">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {userRequests.map((request, index) => (
                        <tr key={index} className="border border-primary hover:bg-gray-50">
                            <td className="w-1/4 px-2 py-2 text-left text-sm text-black-500 border-r border-primary">
                                <div className='flex items-center gap-2'>
                                    <UserRequestIcon className="text-primary" />
                                    <span className='truncate max-w-40' title={request.name}>{request.name}</span>
                                </div>
                            </td>
                            <td className="w-1/6 px-2 py-2 truncate border-r border-primary" title={request.email}>
                                {request.email}
                            </td>
                            <td className="w-1/4 px-2 py-2 truncate border-r border-primary" title={request.institution}>
                                {request.institution}
                            </td>
                            <td className="w-1/6 px-2 py-2 truncate border-r border-primary" title={request.rol}>
                                {request.rol}
                            </td>
                            <td className="w-1/12 px-2 py-2 truncate border-r border-primary" title={request.userRequest}>
                                {request.userRequest}
                            </td>
                            <td className="w-1/12 px-2 py-2 truncate border-r border-primary" title={request.justification}>
                                {request.justification}
                            </td>
                            <td className="w-1/6 px-2 py-2 flex space-x-2">
                                <button title="Aceptar">
                                    <AcceptIcon className="text-primary" />
                                </button>
                                <button title="Rechazar">
                                    <RejectIcon className="text-red-500" />
                                </button>
                                <button title="Eliminar">
                                    <DeleteIcon className="text-gray-500" />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserRequestsPague;
