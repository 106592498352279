
const Categorias = () => {
    return (
        <main className="flex-grow bg-gris1 mt-2">
            <div className="max-w-screen-xl mx-auto p-2">
                <div className="grid grid-row-1 md:grid-row-2 p-2">
                    {/* Sección Individuales */}
                    <div className="">
                        <h2 className="font-bold mb-2 text-primary border-b-2 border-primary">Individuales</h2>
                        <h3 className="font-semibold mb-2">Actividad Física y Cuidado Cardiovascular</h3>
                        <ul className="grid grid-cols-2 gap-4  justify-center text-center p-4">
                            <li className=" border border-primary rounded-md">Realizar actividad física en horarios de menor contaminación.</li>
                            <li className=" border border-primary rounded-md">Mantener un control cardiovascular adecuado y regular.</li>
                        </ul>
                    </div>

                    {/* Sección Colectivas */}
                    <div>
                        <h2 className="font-bold mb-2 text-primary border-b-2 border-primary">Colectivas</h2>
                        <h3 className="font-semibold mb-2">Educación y Sensibilización</h3>
                        <ul className="grid grid-cols-3 gap-6 ">
                            <li className=" border border-primary rounded-md">Ofrecer talleres para padres y cuidadores sobre prevención de infecciones respiratorias.</li>
                            <li className=" border border-primary rounded-md text-center">Participar en actividades de promoción de la salud y cuidado del aire.</li>
                            <li className=" border border-primary rounded-md">Implementar programas de educación ambiental en instituciones de salud.</li>
                        </ul>
                    </div>

                    {/* Otra subcategoría Colectiva */}
                    <div>
                        <h3 className="font-semibold mb-2">Gestión y Análisis de Salud</h3>
                        <ul className="grid grid-cols-2 gap-4  justify-center text-center p-4">
                            <li className=" border border-primary rounded-md">Mejorar la gestión de datos de salud en las IPS para el análisis epidemiológico.</li>
                            <li className=" border border-primary rounded-md">Fortalecer campañas de vacunación y programas de salud comunitaria.</li>
                        </ul>
                    </div>

                    {/* Otra subcategoría Colectiva */}
                    <div>
                        <h3 className="font-semibold mb-2">Conciencia y Movilidad Sostenible</h3>
                        <ul className="grid grid-cols-2 gap-4  justify-center text-center p-4">
                            <li className=" border border-primary rounded-md">Promover el uso de bicicletas y medios de transporte eléctricos.</li>
                            <li className=" border border-primary rounded-md">Fomentar el transporte compartido y el uso responsable de vehículos.</li>
                        </ul>
                    </div>

                    {/* Otra subcategoría Colectiva */}
                    <div>
                        <h3 className="font-semibold mb-2">Infraestructura y Espacios Públicos</h3>
                        <ul className="grid grid-cols-2 gap-4  justify-center text-center p-4">
                            <li className=" border border-primary rounded-md">Mejorar espacios recreativos en áreas de baja circulación vehicular.</li>
                            <li className=" border border-primary rounded-md">Fomentar proyectos de reforestación urbana con apoyo comunitario.</li>
                        </ul>
                    </div>

                    {/* Otra subcategoría Colectiva */}
                    <div>
                        <h3 className="font-semibold mb-2">Transporte y Conducción Responsable</h3>
                        <ul className="grid grid-cols-2 gap-4  justify-center text-center p-4">
                            <li className=" border border-primary rounded-md">Capacitar a conductores en prácticas sostenibles.</li>
                            <li className=" border border-primary rounded-md">Facilitar mesas de diálogo sobre movilidad y calidad del aire con el sector transporte e industria.</li>
                        </ul>
                    </div>

                    {/* Otra subcategoría Colectiva */}
                    <div>
                        <h3 className="font-semibold mb-2">Comunicación y Redes Comunitarias</h3>
                        <ul className="grid grid-cols-2 gap-4  justify-center text-center p-4">
                            <li className=" border border-primary rounded-md">Crear redes comunitarias para denunciar prácticas que afecten la calidad del aire.</li>
                            <li className=" border border-primary rounded-md">Desarrollar campañas de comunicación para que la población conozca medidas en caso de quemas.</li>
                        </ul>
                    </div>
                </div>
                <br/>
            </div>
        </main>
    );
};

export default Categorias;
