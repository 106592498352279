import React from 'react';

const NormatividadLocal: React.FC = () => {
    return (
            <div className="overflow-x-auto p-2 sm:p-8 mx-auto">
                <table className="w-full border border-collapse">
                <thead>
                        <tr>
                            <th colSpan={3}  className="bg-primary text-textColor py-4 text-center">
                                Normatividad Local
                            </th>
                        </tr>
                        <tr className="bg-gris1">
                            <th className="border px-4 py-2 text-center">
                                Norma
                            </th>
                            <th className="border px-4 py-2 text-center">
                                Institución
                            </th>
                            <th className="border px-4 py-2 text-center">
                                Descripción
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr className="bg-gris1">
                                <td className="border px-4 py-2 text-center">
                                    <a href="https://www.metropol.gov.co/ambiental/calidad-del-aire/Documents/PIGECA/PIGECA-Aprobado-Dic-2017.pdf" target="_blank" className="text-blue-600 underline">
                                    Plan Integral de la Gestión de la Calidad del Aire en el Valle de Aburrá.
                                    </a>
                                    <p>Acuerdo Metropolitano 16 de 2017</p>
                                </td>
                                <td className="border px-4 py-2 text-center">Área Metropolitana del Valle de Aburrá</td>
                                <td className="border px-4 py-2">
                                    <p className="ml-4">Plan estratégico para la disminución a corto, mediano y largo plazo de la contaminación atmosférica, siguiendo cinco ejes temáticos transversales y diez ejes temáticos estructurales.</p>
                                </td>
                        </tr>
                        <tr className="bg-gris1">
                            <td className="border px-4 py-2 text-center">
                                <a href="https://www.metropol.gov.co/ambiental/calidad-del-aire/Biblioteca-aire/Estudios-calidad-del-aire/Protocolo-plan-operacional-dic-2015.pdf" target="_blank" className="text-blue-600 underline">
                                Plan Operacional para enfrentar Episodios de contaminación atmosférico en la jurisdicción del Área Metropolitana</a>
                                <p>Acuerdo Metropolitano N° 4 de 2018</p>
                            </td>
                            <td className="border px-4 py-2 text-center">Área Metropolitana del Valle de Aburrá</td>
                            <td className="border px-4 py-2">
                                <p className="ml-4">Se establecen los roles y actividades de los actores involucrados en su ejecución para prevenir los efectos adversos al ambiente y a la salud de la población por la emisión y exposiciones a altos índices de contaminación</p>
                            </td>
                        </tr>
                        <tr className="bg-gris1">
                            <td className="border px-4 py-2 text-center">
                                <a href="https://www.metropol.gov.co/ambiental/calidad-del-aire/Documents/Normatividad/Acuerdo-Metropolitano-17-de-2019.pdf" target="_blank" className="text-blue-600 underline">Sistema de Vigilancia de Salud Ambiental, componente aire y salud del Valle de Aburrá </a>
                                <p> Acuerdo Metropolitano 17 del 2019</p>
                            </td>
                            <td className="border px-4 py-2 text-center">Área Metropolitana del Valle de Aburrá</td>
                            <td className="border px-4 py-2">
                                <p className="ml-4">Se adopta un Sistema de Vigilancia de Salud Ambiental, componente aire y salud del Valle de Aburrá., con el objeto de establecer los procesos de recolección, análisis e interpretación y divulgación de los efectos en salud relacionados con la contaminación del aire.</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
    );
}
export default NormatividadLocal;
