import React, { useState } from 'react';

interface ResetPasswordProps {
    onConfirm: (email: string) => void;
    onCancel: () => void;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ onConfirm, onCancel }) => {
    const [emailInput, setEmailInput] = useState('');

    const handleConfirm = () => {
        onConfirm(emailInput);
    };

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-80 text-center border border-primary">
                <h2 className="text-lg font-semibold mb-4">Restablecer la contraseña</h2>
                <p className="mb-4">Por favor, ingresa tu correo electrónico para recibir instrucciones:</p>
                <input
                    type="email"
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                    placeholder="Correo electrónico"
                    className="w-full mb-4 p-2 border border-primary rounded-md"
                    required
                />
                <div className="flex justify-around">
                    <button 
                        onClick={handleConfirm} 
                        className="bg-primary text-white px-4 py-2 rounded-md"
                        disabled={!emailInput}
                    >
                        Recuperar
                    </button>
                    <button onClick={onCancel} className="bg-red-500 text-white px-4 py-2 rounded-md">Cancelar</button>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
