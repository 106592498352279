import React from 'react';
import { Link } from 'react-router-dom';

const E2016: React.FC = () => {
    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Episodio 2016 para el municipio de Medellín</h1>
                    <br/>
                    <div>
                        <Link to="/analisis-atmosfericos/informacion" className=" px-4 py-2 hover:bg-primary rounded-md underline" >Volver</Link>
                    </div>
                </div>
            </div>
            <div className="p-4 sm:p-8 text-justify text-base md:text-lg max-w-screen-lg mx-auto">
                <div className="border border-primary rounded-md p-4">
                    <h2 className="font-bold text-lg text-center">¿Cómo se han comportado los casos de atención en salud y las defunciones durante los episodios de contaminación del aire en los municipios del Valle de Aburrá?</h2>
                    <p className="mt-2">
                    Los episodios de contaminación del aire, definidos por concentraciones de material particulado que exceden tres veces los niveles aceptados por la OMS, han sido registrados en los municipios del AMVA entre 2015 y 2019. Aquí puedes explorar estos episodios y su impacto en el aumento de defunciones y casos de atención en salud por enfermedades respiratorias y circulatorias agudas.                    </p>
                </div>
            </div>
            <h2 className="font-bold text-primary text-lg text-center mb-4">Análisis de episodios atmosféricos</h2>
            {/* Report 1 */}
            <div className="w-full h-screen mb-6">
                <iframe 
                    allowFullScreen={true} 
                    src="https://app.powerbi.com/view?r=eyJrIjoiYTRmMGI5ODctMmMwOC00Yjc4LWFkNzgtMmMzOTdjMTdkMGVlIiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9"                    title="Report Section" 
                    className="w-full h-full border border-gray-300 rounded-md"
                ></iframe>
            </div>
            <br/>
            <br/>
            {/* Report 2 */}
            <div className="w-full h-screen mb-6">
                <iframe 
                    allowFullScreen={true} 
                    src="https://app.powerbi.com/view?r=eyJrIjoiYmMzNjY3YmYtNTZhMC00ZTllLWFiMWEtZGU4NDZkZWZiNTM4IiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9"
                    title="Report Section" 
                    className="w-full h-full border border-gray-300 rounded-md"
                ></iframe>
            </div>
            <br/>
            <br/>
            {/* Report 3 */}
            <div className="w-full h-screen mb-6">
                <iframe 
                    allowFullScreen={true} 
                    src="https://app.powerbi.com/view?r=eyJrIjoiZTI0MTUzYzItMWVjMi00NjQwLTkwOWYtOTgzOTgzZjY3YmFmIiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9"
                    title="Report Section" 
                    className="w-full h-full border border-gray-300 rounded-md"
                ></iframe>
            </div>
            <br/>
            <br/>
            <div className="flex items-center justify-center">
                <img src='/2016.png' alt="exceso de morbilidad" className="w-[80%]" />
            </div>
            <p className="text-center justify-center items-center ml-8 mr-8">Porcentaje de excesos en los riesgos de morbilidad durante los periodos de alerta roja y alerta naranja, Medellín, 2016</p>
            <div className='p-2 sm:p-8 text-justify text-base md: max-w-screen-xl mx-auto'>
                <div className='font-bold border border-primary rounded-md p-4'>
                    <p >La alta exposición a PM2.5 es nociva para la salud de la población. Las enfermedades respiratorias como neumonía, asma e infecciones respiratorias agudas se incrementan tanto en los días de alerta naranjas como en los días de alerta roja, particularmente en estos últimos.</p>
                </div>
            </div>
        </main>
    );
};

export default E2016;
