import React, { useRef } from 'react';
import ReportList from './reportList';
import Timeline from './timeline';

const Search: React.FC = () => {


    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Investigación en Salud y Ambiente</h1>
                </div>
            </div>
            <div className="p-4 sm:p-8 text-justify text-base md:text-lg max-w-screen-lg mx-auto">
                <p className='border border-primary rounded-md p-2'>
                SIVISA ejemplifica buenas prácticas de colaboración interinstitucional e intersectorial en pro del ambiente y la salud. Desde 2016, el Área Metropolitana del Valle de Aburrá y la Facultad Nacional de Salud Pública de la Universidad de Antioquia han trabajado juntos en la gestión del conocimiento, desarrollando metodologías innovadoras y herramientas para el análisis y la gestión social del conocimiento. A continuación, se presentan los informes de resultados de los análisis realizados sobre los efectos en la salud relacionados con la calidad del aire.
                </p>
                <br />
            </div>
            <div className=''>
                <p className='font-bold text-xl text-primary text-center'>Resultados de las investigaciones realizadas</p>
                <br/>
                <Timeline/>
            </div>
        </main>
    );
};

export default Search;
