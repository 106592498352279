import React from 'react';

interface UserRequestConfirmationProps {
    userType: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const UserRequestConfirmation: React.FC<UserRequestConfirmationProps> = ({ userType, onConfirm, onCancel }) => {
    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-80 text-center border border-primary">
                <h2 className="text-lg font-semibold mb-4">Confirmación de Solicitud</h2>
                <p className="mb-6">¿Estás seguro que deseas solicitar el perfil de <strong>{userType}</strong>?</p>
                <div className="flex justify-around">
                    <button onClick={onConfirm} className="bg-primary text-white px-4 py-2 rounded-md">Aceptar</button>
                    <button onClick={onCancel} className="bg-red-500 text-white px-4 py-2 rounded-md">Cancelar</button>
                </div>
            </div>
        </div>
    );
};

export default UserRequestConfirmation;
