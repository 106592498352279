import React, { useState } from 'react';
import { PasswordIcon1 } from '../../Utils/Icons';

const datos = [
    { name: 'RIPS 1 EJEMPLO', type: 'Salud', year: '2011', uploadData: '17/10/2024' },
    { name: 'RIPS 2 EJEMPLO', type: 'Salud', year: '2013', uploadData: '17/10/2024' },
    { name: 'DATA AMBIENTE EJEMPLO', type: 'Ambiente', year: '2012', uploadData: '17/10/2024' },
    { name: 'RIPS 3 EJEMPLO', type: 'Salud', year: '2012', uploadData: '17/10/2024' },
    { name: 'DATA AMBIENTE 1 EJEMPLO', type: 'Ambiente', year: '2011', uploadData: '17/10/2024' },
    { name: 'RIPS 1 EJEMPLO', type: 'Salud', year: '2011', uploadData: '17/10/2024' },
    { name: 'RIPS 2 EJEMPLO', type: 'Salud', year: '2013', uploadData: '17/10/2024' },
    { name: 'DATA AMBIENTE EJEMPLO', type: 'Ambiente', year: '2012', uploadData: '17/10/2024' },
    { name: 'RIPS 3 EJEMPLO', type: 'Salud', year: '2012', uploadData: '17/10/2024' },
    { name: 'DATA AMBIENTE 1 EJEMPLO', type: 'Ambiente', year: '2011', uploadData: '17/10/2024' },
    { name: 'RIPS 1 EJEMPLO', type: 'Salud', year: '2011', uploadData: '17/10/2024' },
    { name: 'RIPS 2 EJEMPLO', type: 'Salud', year: '2013', uploadData: '17/10/2024' },
    { name: 'DATA AMBIENTE EJEMPLO', type: 'Ambiente', year: '2012', uploadData: '17/10/2024' },
    { name: 'RIPS 3 EJEMPLO', type: 'Salud', year: '2012', uploadData: '17/10/2024' },
    { name: 'DATA AMBIENTE 1 EJEMPLO', type: 'Ambiente', year: '2011', uploadData: '17/10/2024' },
    { name: 'RIPS 1 EJEMPLO', type: 'Salud', year: '2011', uploadData: '17/10/2024' },
    { name: 'RIPS 2 EJEMPLO', type: 'Salud', year: '2013', uploadData: '17/10/2024' },
    { name: 'DATA AMBIENTE EJEMPLO', type: 'Ambiente', year: '2012', uploadData: '17/10/2024' },
    { name: 'RIPS 3 EJEMPLO', type: 'Salud', year: '2012', uploadData: '17/10/2024' },
    { name: 'DATA AMBIENTE 1 EJEMPLO', type: 'Ambiente', year: '2011', uploadData: '17/10/2024' },
    { name: 'RIPS 1 EJEMPLO', type: 'Salud', year: '2011', uploadData: '17/10/2024' },
    { name: 'RIPS 2 EJEMPLO', type: 'Salud', year: '2013', uploadData: '17/10/2024' },
    { name: 'DATA AMBIENTE EJEMPLO', type: 'Ambiente', year: '2012', uploadData: '17/10/2024' },
    { name: 'RIPS 3 EJEMPLO', type: 'Salud', year: '2012', uploadData: '17/10/2024' },
    { name: 'DATA AMBIENTE 1 EJEMPLO', type: 'Ambiente', year: '2011', uploadData: '17/10/2024' },
    { name: 'RIPS 1 EJEMPLO', type: 'Salud', year: '2011', uploadData: '17/10/2024' },
    { name: 'RIPS 2 EJEMPLO', type: 'Salud', year: '2013', uploadData: '17/10/2024' },
    { name: 'DATA AMBIENTE EJEMPLO', type: 'Ambiente', year: '2012', uploadData: '17/10/2024' },
    { name: 'RIPS 3 EJEMPLO', type: 'Salud', year: '2012', uploadData: '17/10/2024' },
    { name: 'DATA AMBIENTE 1 EJEMPLO', type: 'Ambiente', year: '2011', uploadData: '17/10/2024' }
];

const PreprocessPague: React.FC = () => {
    const [selected, setSelected] = useState<boolean[]>(new Array(datos.length).fill(false));
    const [files, setFiles] = useState<File[]>([]);
    const [tipo, setTipo] = useState<string>("");
    const [anio, setAnio] = useState<string>("");

    const handleCheckboxChange = (index: number) => {
        const updatedSelection = [...selected];
        updatedSelection[index] = !updatedSelection[index];
        setSelected(updatedSelection);
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFiles(Array.from(event.target.files));
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const droppedFiles = event.dataTransfer.files;
        setFiles(Array.from(droppedFiles));
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleCancelar = () => {
        // Limpiar archivos seleccionados, tipo y año
        setFiles([]);
        setTipo("");
        setAnio("");
    };

    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Preprocesamiento de la Data</h1>
                </div>
            </div>

            <div className="container mx-auto p-4 bg-white border border-primary rounded-md mb-4 mt-8 ">
                <h2 className="text-xl font-semibold mb-4">Cargar Documentos</h2>
                <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center md:justify-between mb-4">
                    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                        <select
                            value={tipo}
                            onChange={(e) => setTipo(e.target.value)}
                            className="border border-primary rounded px-4 py-2"
                        >
                            <option value="" disabled hidden>Tipo de Documento</option>
                            <option value="salud">Salud</option>
                            <option value="ambiente">Ambiente</option>
                        </select>
                        <select
                            value={anio}
                            onChange={(e) => setAnio(e.target.value)}
                            className="border border-primary rounded px-4 py-2"
                        >
                            <option value="" disabled hidden>Seleccione el año</option>
                            <option value="2010">2010</option>
                            <option value="2011">2011</option>
                        </select>
                    </div>
                    <div className="flex space-x-4 justify-center md:flex">
                        <button className="bg-primary text-white px-4 py-2 rounded flex items-center space-x-2" onClick={() => document.getElementById('fileInput')?.click()}>
                            <span>Seleccionar</span>
                        </button>
                        <input
                            id="fileInput"
                            type="file"
                            multiple
                            className="hidden"
                            onChange={handleFileSelect}
                        />
                        <button className="bg-primary text-white px-4 py-2 rounded">Subir</button>
                        <button className="bg-primary text-white px-4 py-2 rounded" onClick={handleCancelar}>Cancelar</button>
                    </div>
                </div>

                <div
                    className="hidden text-center text-primary justify-center items-center md:flex border-2 border-dashed border-primary rounded p-8"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                    {files.length > 0 ? (
                        <p>{files.map(file => file.name).join(", ")}</p>
                    ) : (
                        <p>Arrastra y suelta aquí los archivos a cargar</p>
                    )}
                </div>

                <div className="md:hidden border-2 border-dashed rounded border-primary text-center text-primary mt-4">
                    {files.length > 0 ? (
                        <p>{files.map(file => file.name).join(", ")}</p>
                    ) : (
                        <p>No hay archivos seleccionados</p>
                    )}
                </div>
            </div>

            <div className="container mx-auto p-4 border border-primary rounded-md mb-4">
                <h2 className="text-xl font-semibold mb-4">Historial de Datos Cargados</h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full border border-primary">
                        <thead>
                            <tr className='font-bold text-white text-base bg-primary'>
                                <th className="py-2 px-4">
                                    <input type="checkbox" disabled />
                                </th>
                                <th className="py-2 px-4">Documento</th>
                                <th className="py-2 px-4">Tipo documento</th>
                                <th className="py-2 px-4">Año de la BD</th>
                                <th className="py-2 px-4">Fecha de carga</th>
                                <th className="py-2 px-4">Visualización</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.map((dato, index) => (
                                <tr key={index} className="text-center bg-gris1">
                                    <td className="py-2 px-4 border">
                                        <input
                                            type="checkbox"
                                            checked={selected[index]}
                                            onChange={() => handleCheckboxChange(index)}
                                            className="accent-primary "
                                        />
                                    </td>
                                    <td className="py-2 px-4 border">{dato.name}</td>
                                    <td className="py-2 px-4 border">{dato.type}</td>
                                    <td className="py-2 px-4 border">{dato.year}</td>
                                    <td className="py-2 px-4 border">{dato.uploadData}</td>
                                    <td className="py-2 px-4 border">
                                        <PasswordIcon1 className="w-6 h-6 mx-auto text-primary" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='mb-4 justify-center text-center items-center'>
                <button className='bg-primary text-white px-4 py-2 rounded'>
                    Visualizar Resultados
                </button>
            </div>
        </main>
    );
};

export default PreprocessPague;