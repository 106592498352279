import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
    return (
        <footer className="bg-primary p-4 text-textColor">
        <div className="grid grid-cols-3 ml-4 mr-4 text-center">
            <p className="text-left">En colaboración con Universidad de Antioquia</p>
            <Link to="/contacto" className="hover:text-secondary focus:underline">Contáctenos</Link>
            <p className="text-right hover:text-secondary focus:underline">Versión 5.0</p>
        </div>
        </footer>
    );
};

export default Footer;
