import React from 'react';
import ReportList from './SearchPage/reportList';

const DocumentsPague: React.FC = () => {
    const docs = [
        {
            name: "PROTOCOLO DEL SISTEMA DE VIGILANCIA EN SALUD AMBIENTAL CON ENFASIS EN CALIDAD DEL AIRE Y SUS EFECTOS SOBRE LA SALUD - VALLE DE ABURRÁ",
            href: '/PROTOCOLO SIVISA. 2022.pdf',
            
        },
        {
            name: "PLAN DE ACCIÓN PARA LA IMPLEMENTACIÓN DEL PLAN OPERACIONAL PARA ENFRENTAR EPISODIOS DE CONTAMINACIÓN ATMOSFÉRICA (POECA) EN EL ÁREA METROPOLITANA DEL VALLE DE ABURRÁ",
            href: '/Plan-de-Accion-POECA-2021.pdf',
            
        },
        {
            name: "PLAN INTEGRAL DE GESTIÓN DE CALIDAD DEL AIRE PARA EL ÁREA METROPOLITANA DEL VALLE DE ABURRÁ PIGECA 2017 - 2030",
            href: '/PIGECA-Aprobado-Dic-2017.pdf',
            
        },
        {
            name: ["PLAN DECENAL DE SALUD PÚBLICA", "PDSP 2022 - 2031"],
            href: '/documento-plan-decenal-salud-publica-2022-2031.pdf',
            
        },

    ];
    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Documentación</h1>
                </div>
            </div>
            <div className="p-2 sm:p-8 mx-auto grid grid-cols-1 md:grid-cols-2 gap-4">
                {docs.map((doc, index) => (
                    <div key={index} className=" border-primary rounded-lg bg-primary">
                        <div className="p-4 text-center">
                        {Array.isArray(doc.name) ? (
                                doc.name.map((line, lineIndex) => (
                                    <h2 key={lineIndex} className="text-lg text-textColor font-semibold">
                                        {line}
                                    </h2>
                                ))
                            ) : (
                                <h2 className="text-lg text-textColor font-semibold">{doc.name}</h2>
                            )}
                        </div>
                        <iframe 
                            src={doc.href} 
                            width="100%" 
                            height="600px" 
                            title={Array.isArray(doc.name) ? doc.name.join(' ') : doc.name}
                            className="border-2 border-secondary"
                        ></iframe>
                    </div>
                ))}
            </div>
        </main>
    );
};

export default DocumentsPague;
