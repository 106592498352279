import React from 'react';
import { VideoGrid } from './videoGrid';

const AccionesSalud: React.FC = () => {
    const videos1 = [
        { src: "https://www.youtube.com/watch?v=PkRyJ2Lpr6Q&t", title: "#UnMejorAire Valle de Aburrá" },
        { src: "https://www.youtube.com/watch?v=WkyLtTIP7Xg&t", title: "¿Qué es el POECA?" },
        { src: "https://www.youtube.com/watch?v=sonepi3GKPI", title: "Un Mejor Aire" }
    ];

    const videos2 = [
        { src: "https://www.youtube.com/watch?v=ZB-HP3XmLO8&t=1s", title: "Breathelife: Barranquilla Colombia"},
        { src: "https://www.youtube.com/watch?v=h_0An181E5Q", title: "Cambio Climatico y Salud"},
        { src: "https://www.youtube.com/watch?v=110rsHDI4SA", title: "El aire es un derecho"}
    ];

    const videos3 = [
        { src: "https://www.youtube.com/watch?v=W2es3I_-nyE", title: "Air Pollution & Climate Change", style: 'italic' },
        { src: "https://www.youtube.com/watch?v=wNyOd_BZRAE", title: "A Fragile Stage", style: 'italic' },
        { src: "https://www.youtube.com/watch?v=IPXIvaXI0xg", title: "EEA Air Quality animation", style: 'italic' }
    ];
    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Acciones en Salud</h1>
                </div>
            </div>
                <div className="p-4 sm:p-8 text-justify text-base md:text-lg max-w-screen-lg mx-auto">
                    <p className='border border-primary rounded-md p-4'>
                    Las acciones para la salud en nuestros municipios son fundamentales para mejorar la calidad del aire. Es esencial entender que cada uno de nosotros contribuye a la contaminación, por lo que adoptar algunas recomendaciones y medidas nos ayuda a ser parte de la solución y a proteger nuestra salud.                    </p>
                    <br />
                    <p className='border border-primary rounded-md p-4'> El <span className='font-bold'>Área Metropolitana del Valle de Aburrá - AMVA: </span>
                    es una entidad administrativa de derecho público que asocia a los diez municipios que conforman el Valle de Aburrá. Medellín, como ciudad núcleo, está rodeada por los municipios de Barbosa, Girardota, Copacabana, Bello, Itagüí, Sabaneta, Envigado, La Estrella y Caldas; interconectados por dinámicas e interrelaciones territoriales, ambientales, económicas, sociales, demográficas, culturales y tecnológicas. Para programar y coordinar su desarrollo sustentable y de ordenamiento territorial, promover el desarrollo humano, y optimizar la prestación de servicios públicos, es necesario un ente coordinador como el AMVA.
                    </p>
                    <br />
                    <div>
                        <VideoGrid videos={videos1} />
                    </div>
                </div>
                <div className="p-4 sm:p-8 text-justify text-base md:text-lg max-w-screen-lg mx-auto">
                    <p className='text-center font-bold text-xl '>
                    Iniciativas internacionales y medidas implementadas para mejorar la calidad del aire </p>
                    <br />
                    <p className='border border-primary rounded-md p-4'> <a href='https://breathelife2030.org/es/' target="_blank" rel="noopener noreferrer " className='font-bold text-primary italic focus:underline'>RED BREATHLIFE:  </a>
                    BreatheLife es una iniciativa de la Coalición Clima y Aire Limpio, liderada por la OMS y el programa de las Naciones Unidas para el Medio Ambiente. Esta campaña global tiene como objetivo movilizar a las ciudades y a las personas para proteger nuestra salud y nuestro planeta de los efectos de la contaminación del aire.
                    <a href='https://breathelife2030.org/es/' target="_blank" rel="noopener noreferrer " className='font-bold text-primary underline'> Conoce más dando click aquí &#x1F913;
                    </a>
                    </p>
                    <br />
                    <p className='border border-primary rounded-md p-4'> <a href='https://www.minambiente.gov.co/asuntos-ambientales-sectorial-y-urbana/estrategia-nacional-de-calidad-del-aire-enca/' target="_blank" rel="noopener noreferrer " className='font-bold text-primary focus:underline'>Estrategia Nacional de Calidad del Aire – ENCA: </a>
                    El Ministerio de Ambiente y Desarrollo sostenible ha desarrollado la Estrategia Nacional de Calidad del Aire en busca de mejorar la calidad del aire con énfasis en la reducción de material particulado en áreas urbanas, garantizando la protección del ambiente y la salud de los colombianos.
                    <a href='https://www.minambiente.gov.co/asuntos-ambientales-sectorial-y-urbana/estrategia-nacional-de-calidad-del-aire-enca/' target="_blank" rel="noopener noreferrer " className='font-bold text-primary underline'> Conoce más dando click aquí &#x1F913;</a>
                    </p>
                    <br />
                    <p className='border border-primary rounded-md p-4'> <a href='https://iris.paho.org/bitstream/handle/10665.2/55703/OPSCDECE220003_spa.pdf?sequence=1&isAllowed=y' target="_blank" rel="noopener noreferrer " className='font-bold text-primary focus:underline'>Funciones Esenciales de la Salud Pública Ambiental – OPS: </a>
                    La Organización Panamericana de la Salud presenta un marco para implementar la Agenda para las Américas sobre salud, medioambiente y cambio climático 2021-2030, que se estructura en torno a las funciones esenciales de la salud pública centradas en los determinantes ambientales de la salud.                    
                    <a href='https://iris.paho.org/bitstream/handle/10665.2/55703/OPSCDECE220003_spa.pdf?sequence=1&isAllowed=y' target="_blank" rel="noopener noreferrer " className='font-bold text-primary underline'> Conoce más dando click aquí &#x1F913;</a>
                    </p>
                    <div>
                        <VideoGrid videos={videos2} />
                    </div>
                    <p className='border border-primary rounded-md p-4'> <a href='https://iris.paho.org/handle/10665.2/55212' target="_blank" rel="noopener noreferrer " className='font-bold text-primary focus:underline'> Agenda para las Américas sobre salud, medioambiente y cambio climático 2021-2030: </a>
                    La Organización Panamericana de la Salud presenta la Agenda para las Américas sobre salud, medioambiente y cambio climático 2021-2030. Esta agenda es un llamamiento al sector de la salud para que lidere la labor a fin de abordar los determinantes ambientales de la salud en la Región de las Américas.
                    <a href='https://iris.paho.org/handle/10665.2/55212' target="_blank" rel="noopener noreferrer " className='font-bold text-primary underline'> Conoce más dando click aquí &#x1F913;</a>
                    </p>
                    <br />
                    <p className='border border-primary rounded-md p-4'> <a href='https://www.stateofglobalair.org' target="_blank" rel="noopener noreferrer " className='font-bold text-primary italic focus:underline'>State of Global Air report: </a>
                    El informe de sobre la Situación Global del Aire y su sitio web interactivo presenta un análisis completo de los niveles y tendencias en la calidad del aire y la salud de todos los países del mundo. Son producidos anualmente por el Instituto de Efectos de la Salud y el proyecto de Carga Global de Enfermedades (GBD) del Instituto para la Métrica y Evaluación de la Salud (IHME).
                    <a href='https://www.stateofglobalair.org' target="_blank" rel="noopener noreferrer " className='font-bold text-primary underline'> Conoce más dando click aquí &#x1F913;</a>
                    </p>
                    <br />
                    <p className='border border-primary rounded-md p-4'> <a href='https://www.epa.gov/air-quality-management-process' target="_blank" rel="noopener noreferrer " className='font-bold text-primary italic focus:underline'> Air Quality Management Process-EPA: </a>
                    La Agencia de Protección Ambiental de Estados Unidos (EPA por sus siglas en inglés) presenta una página web que introduce a la comunidad internacional, el proceso de gestión de la calidad del aire que se realiza en EEUU.
                    <a href='https://www.epa.gov/air-quality-management-process' target="_blank" rel="noopener noreferrer " className='font-bold text-primary underline'> Conoce más dando click aquí &#x1F913;</a>
                    </p>
                    <br />
                    <p className='border border-primary rounded-md p-4'> <a href='https://www.eea.europa.eu/themes/air' target="_blank" rel="noopener noreferrer " className='font-bold text-primary italic focus:underline'>Air pollution page European Environmental Agency:  </a>
                    La Agencia Europea para el Medio ambiente, (EEA por sus siglas en inglés) presenta una página web en la que presenta su experiencia en la gestión de la calidad del aire y en la medición ellos impactos en salud relacionados a la contaminación atmosférica, en los países de la región.
                    <a href='https://www.eea.europa.eu/themes/air' target="_blank" rel="noopener noreferrer " className='font-bold text-primary underline'> Conoce más dando click aquí &#x1F913;</a>
                    </p>
                    <div>
                        <VideoGrid videos={videos3} />
                    </div>
                </div>
        </main>
    );
};

export default AccionesSalud;
