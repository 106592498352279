import React, { useEffect, useState } from 'react';
import { PasswordIcon1, PasswordIcon2 } from '../../Utils/Icons';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth, Role } from '../Authentication';
import ResetPassword from './resetPassword';
import { validUsers } from './validUser';

export interface ValidUser {
    username: string;
    password: string;
    features: {
        entity: string;
        rol: Role;
        work: string;
        location?: string;
        email: string;
    };
}

const Login: React.FC = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showResetPassword, setShowResetPassword] = useState(false);
    const navigate = useNavigate();
    const { login, logout } = useAuth();

    useEffect(() => {
        const timeout = setTimeout(() => {
            logout();
            navigate('/');
        }, 1800000);

        return () => clearTimeout(timeout);
    }, [navigate]);

    const handleLogin = (e: React.FormEvent) => {
        e.preventDefault();

        const user = validUsers.find(u => u.features.email.toLowerCase() === email.toLowerCase() && u.password === password);
        
        if (user) {
            login(user);
            navigate('/perfil');
        } else {
            setError('Nombre de usuario o contraseña incorrectos');
        }
    };

    // const handleLogin = async (e: React.FormEvent) => {
    //     e.preventDefault();

    //     try {
    //         const response = await axios.post('http://localhost:5000/api/users/login', { email, password });
    //         login(response.data);
    //         navigate('/perfil');
    //     } catch (error) {
    //         if (axios.isAxiosError(error) && error.response && error.response.data) {
    //             setError(error.response.data.error);
    //         } else {
    //             setError('Error al iniciar sesión');
    //         }
    //     }
    // };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleForgotPassword = () => {
        setShowResetPassword(true);
    };

    const handleConfirmReset = () => {
        alert('Se ha enviado un enlace de recuperación a su correo electrónico.');
        setShowResetPassword(false);
    };

    const handleCancelReset = () => {
        setShowResetPassword(false);
    };

    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Iniciar Sesión</h1>
                </div>
            </div>
            <div className="flex justify-center items-center p-12">
                <div className="w-full max-w-md bg-white p-8 border border-primary rounded-md shadow-lg">
                    <form onSubmit={handleLogin}>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold">Correo Electrónico</label>
                            <input
                                className="w-full p-2 border border-primary rounded-md"
                                type="email"
                                placeholder="Correo Electrónico"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-6 relative">
                            <label className="block text-sm font-semibold">Contraseña</label>
                            <input
                                className="w-full p-2 border border-primary rounded-md"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Contraseña"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="absolute top-2 right-1 translate-y-1/2 cursor-pointer"
                            >
                                {showPassword ? <PasswordIcon2 /> : <PasswordIcon1 />}
                            </button>
                        </div>
                        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
                        <button type="submit" className="w-full bg-primary text-textColor py-2 rounded-md">Ingresar</button>
                        <div className="text-center mt-4">
                            <a href="#" className="text-primary" onClick={handleForgotPassword}>Olvidé la contraseña</a>
                        </div>
                        <div className="text-center mt-2">
                            <p>No tienes una cuenta? &nbsp;
                                <Link to="/solicitar-cuenta" className="text-primary font-semibold">Solicitar cuenta</Link>
                            </p>
                        </div>
                    </form>
                </div>
            </div>

            {showResetPassword && (
                <ResetPassword 
                    onConfirm={handleConfirmReset}
                    onCancel={handleCancelReset}
                />
            )}
        </main>
    );
};

export default Login;
