import React from 'react';

const AcuerdoInternacional: React.FC = () => {
    return (
    <div className="overflow-x-auto p-2 sm:p-8 mx-auto">
        <table className="w-full border border-collapse">
            <thead>
                <tr>
                    <th colSpan={3} className="bg-primary text-textColor py-4 text-center">
                        Acuerdos Internacionales
                    </th>
                </tr>
                <tr>
                    <th className="border px-4 py-2 text-center">
                        <p>Norma</p>
                    </th>
                    <th className="border px-4 py-2 text-center">
                        <p>Institución</p>
                    </th>
                    <th className="border px-4 py-2 text-center">
                        <p>Descripción</p>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr className="bg-gris1">
                    <td className="border px-4 py-2 text-center">
                        <a href="https://www.un.org/es/conferences/environment/rio2012" target="_blank" className="text-blue-600 underline">Rio + 20: El futuro que queremos.</a>
                        <br/>
                        <a href="https://www.un.org/es/conferences/environment/rio2012" target="_blank" className="text-blue-600 underline">Tercera Conferencia sobre el Desarrollo Sostenible, en el año 2012.</a>
                    </td>
                    <td className="border px-4 py-2 text-center">Organización de las Naciones Unidas-ONU</td>
                    <td className="border px-4 py-2">
                        <p className="ml-4">Los Estados renuevan su compromiso al desarrollo sostenible y a la promoción de un futuro sustentable. Se reconoce el derecho que tiene toda persona al acceso a la información y a la oportunidad de participación en la toma de decisiones.</p>
                    </td>
                </tr>
                <tr>
                    <td className="border px-4 py-2 text-center">
                        <a href="https://www.un.org/sustainabledevelopment/es/objetivos-de-desarrollo-sostenible/" target="_blank" className="text-blue-600 underline">Objetivos de Desarrollo Sostenible 2015-2030</a>
                    </td>
                    <td className="border px-4 py-2 text-center">Organización de las Naciones Unidas-ONU</td>
                    <td className="border px-4 py-2">
                        <p className="ml-4">Conjunto de objetivos globales para proteger el planeta y asegurar la prosperidad para todos como parte de una nueva agenda de desarrollo sostenible.</p>
                        <p className="ml-4">Se mencionan pautas para garantizar el consumo y producción sostenible, así como medidas para combatir el cambio climático y sus efectos. Incluye 17 objetivos con 169 metas.</p>
                    </td>
                </tr>
                <tr className="bg-gris1">
                    <td className="border px-4 py-2 text-center">
                        <a href="https://iris.paho.org/bitstream/handle/10665.2/55212/OPSCDECE210004_spa.pdf?sequence=1&isAllowed=y" target="_blank" className="text-blue-600 underline">Agenda para las Américas sobre salud, medioambiente y cambio climático 2021-2030</a>
                    </td>
                    <td className="border px-4 py-2 text-center">Organización Panamericana de la Salud</td>
                    <td className="border px-4 py-2">
                        <p className="ml-4">El objetivo es promover el bienestar de todos a todas las edades, utilizando un enfoque sostenible y equitativo en el que se otorgue prioridad a la reducción de las inequidades en la salud.</p>
                        <p className="ml-4">Se centra en: mejorar el desempeño de los programas e instituciones de salud pública ambiental; fomentar sistemas de salud sostenibles y resilientes desde el punto de vista medioambiental; y promover ciudades y comunidades saludables y resilientes desde el punto de vista medioambiental.</p>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
    );
} 
export default AcuerdoInternacional;
