
const Disminuir = () => {
    const items = [
        { icon: '/icon1.png', text: 'Usa adecuada y eficientemente la energía eléctrica, el gas y la gasolina.' },
        { icon: '', text: 'Realiza mantenimientos periódicos a pilotos de estufas y calentadores de gas.' },
        { icon: '', text: 'Evita el uso de leña para calentar o cocinar.' },
        { icon: '', text: 'Utiliza electrodomésticos de bajo consumo de energía.' },
        { icon: '', text: 'Ahorra energía y usa bombillas de bajo consumo.' },
        { icon: '', text: 'Siembra un árbol.' },
        { icon: '', text: 'Apaga las luces y los electrodomésticos que no estés usando.' },
        { icon: '', text: 'Favorece el consumo de productos limpios, orgánicos.' },
        { icon: '', text: 'Usa Internet para hacer reuniones de trabajo.' },
        { icon: '', text: 'Utiliza Internet para realizar trámites y pagos.' }
    ];
    
    return (
        <main className="flex-grow bg-gris1 mt-2">
            <div className="max-w-screen-xl mx-auto p-2">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    {items.map((item, index) => (
                        <div key={index} className="border border-primary rounded-md p-4 flex items-center space-x-4">
                            <img src={item.icon} alt="" className="w-8 h-8" />
                            <p className="text-center">{item.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </main>
    );
};

export default Disminuir;
