import React, { useState } from 'react';
import { DonwloadIcon, EyeIcon, UploadIcon } from '../../Utils/Icons';
import UserRequestConfirmation from './userRequestConfirmation';
import Tooltip from '../Tooltip';

const UserRequest: React.FC = () => {
    const [selectedUserType, setSelectedUserType] = useState<string | null>(null);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [entity, setEntity] = useState('');
    const [location, setLocation] = useState('');
    const [work, setWork] = useState('');
    const [justification, setJustification] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const maxLength = 200;

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setJustification(event.target.value);
    };

    const handleUserTypeSelect = (type: string) => {
        setSelectedUserType(type);
        if (type !== 'Técnico') {
            setLocation('');
            setWork('');
        }
    };

    const handleSolicitarClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (username && email && entity && justification && selectedUserType) {
            if (selectedUserType === 'Técnico' && (!location || !work)) {
                alert("Por favor, completa los campos adicionales para el rol de Técnico.");
            } else {
                setShowConfirmation(true);
            }
        } else {
            alert("Por favor, completa todos los campos requeridos.");
        }
    };

    const handleConfirm = () => {
        setShowConfirmation(false);
        alert("Solicitud enviada exitosamente.");
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    return (
        <main className="flex-grow bg-gris1 ">
            <div className="bg-cover bg-center h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Solicitar Cuenta</h1>
                </div>
            </div>
            <div className="flex justify-center items-center p-12">
                <div className="w-full max-w-md bg-white p-8 border border-primary rounded-md shadow-lg">
                    <form>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold">Nombre Completo</label>
                            <input 
                                className="w-full p-2 border border-primary rounded-md" 
                                type="text" 
                                placeholder="Nombre" 
                                maxLength={35} 
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required 
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-sm font-semibold">Correo electrónico</label>
                            <input 
                                className="w-full p-2 border border-primary rounded-md" 
                                type="email" 
                                placeholder="Correo electrónico" 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required 
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold">Institución
                                <Tooltip text="La institución a la que pertenece, por ejemplo: AMVA, Alcaldía de Bello" />
                            </label>
                            <input 
                                className="w-full p-2 border border-primary rounded-md" 
                                type="text" 
                                placeholder="Institución" 
                                maxLength={51}
                                value={entity}
                                onChange={(e) => setEntity(e.target.value)}
                                required 
                            />
                        </div>

                        {/* Selección del tipo de usuario */}
                        <div className="mb-6">
                            <label className="block text-sm font-semibold mb-2">Tipo de usuario a solicitar</label>
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                                {/* Opción Ciudadano */}
                                <div className={`p-3 border rounded-md cursor-pointer ${selectedUserType === 'Ciudadano' ? 'border-analist' : 'border-gray-300'}`}
                                    onClick={() => handleUserTypeSelect('Ciudadano')}
                                >
                                    <h3 className="text-analist font-bold mb-3 text-center">Ciudadano</h3>
                                    <div className="grid grid-cols-2 gap-1 items-center">
                                        <EyeIcon className="w-8 h-8 text-analist" />
                                        <p className="text-xs text-analist">Vista de la data.</p>
                                        <DonwloadIcon className="w-8 h-8 text-secondary" />
                                        <p className="text-xs text-secondary">Descarga de la data.</p>
                                        <UploadIcon className="w-10 h-10 text-secondary" />
                                        <p className="text-xs text-secondary">Subida de data y reportes.</p>
                                    </div>
                                </div>
                                {/* Opción Técnico */}
                                <div className={`p-3 border rounded-md cursor-pointer ${selectedUserType === 'Técnico' ? 'border-cience' : 'border-gray-300'}`}
                                    onClick={() => handleUserTypeSelect('Técnico')}
                                >
                                    <h3 className="text-cience font-bold mb-3 text-center">Técnico</h3>
                                    <div className="grid grid-cols-2 gap-1 items-center">
                                        <EyeIcon className="w-8 h-8 text-cience" />
                                        <p className="text-xs text-cience">Vista de la data.</p>
                                        <DonwloadIcon className="w-8 h-8 text-cience" />
                                        <p className="text-xs text-cience">Descarga de la data.</p>
                                        <UploadIcon className="w-10 h-10 text-secondary" />
                                        <p className="text-xs text-secondary">Subida de data y reportes.</p>
                                    </div>
                                </div>
                                <div className={`p-2 border rounded-md cursor-pointer ${selectedUserType === 'Tomador de Decisiones' ? 'border-cience2' : 'border-gray-300'}`}
                                    onClick={() => handleUserTypeSelect('Tomador de Decisiones')}
                                >
                                    <h3 className="text-cience2 font-bold text-sm text-center">Tomador de Decisiones</h3>
                                    <div className="grid grid-cols-2 gap-1 items-center">
                                        <EyeIcon className="w-8 h-8 text-cience2" />
                                        <p className="text-xs text-cience2">Vista de la data.</p>
                                        <DonwloadIcon className="w-8 h-8 text-cience2" />
                                        <p className="text-xs text-cience2">Descarga de la data.</p>
                                        <UploadIcon className="w-10 h-10 text-cience2" />
                                        <p className="text-xs text-cience2">Subida de data y reportes.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Campos adicionales para Técnico */}
                        {selectedUserType === 'Técnico' && (
                            <>
                                <div className="mb-4">
                                    <label className="block text-sm font-semibold">Municipio
                                        <Tooltip text="El municipio al que pertenece, por ejemplo: Envigado, Medellín" />
                                    </label>
                                    <input 
                                        className="w-full p-2 border border-primary rounded-md" 
                                        type="text" 
                                        placeholder="Municipio" 
                                        value={location}
                                        onChange={(e) => setLocation(e.target.value)}
                                        required 
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-sm font-semibold">Área de Trabajo
                                    <Tooltip text="Ejemplo: Secretaría de Ambiente, Secretaría de Salud, etc." />
                                    </label>
                                    <input 
                                        className="w-full p-2 border border-primary rounded-md" 
                                        type="text" 
                                        placeholder="Área de Trabajo" 
                                        value={work}
                                        onChange={(e) => setWork(e.target.value)}
                                        required 
                                    />
                                </div>
                            </>
                        )}

                        <div className="mb-6">
                            <label className="block text-sm font-semibold">Justificación de su solicitud</label>
                            <div className="relative">
                                <textarea
                                    className="w-full p-2 border border-primary rounded-md"
                                    rows={4}
                                    placeholder="Escriba una justificación detallada sobre el rol que está solicitando y el por qué lo necesita."
                                    maxLength={maxLength}
                                    value={justification}
                                    onChange={handleChange}
                                    required
                                ></textarea>
                                <span className="absolute bottom-2 right-2 text-sm text-primary">
                                    {justification.length}/{maxLength} caracteres
                                </span>
                            </div>
                        </div>
                        <button onClick={handleSolicitarClick} className="w-full bg-primary text-textColor py-2 rounded-md">Solicitar</button>
                    </form>
                </div>
            </div>
            {showConfirmation && selectedUserType && (
                <UserRequestConfirmation 
                    userType={selectedUserType} 
                    onConfirm={handleConfirm} 
                    onCancel={handleCancel} 
                />
            )}
        </main>
    );
};

export default UserRequest;
