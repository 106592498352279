import React from 'react';
import { useAuth } from '../Authentication';

const PerfilPague: React.FC = () => {
    const { user } = useAuth();
    const roleDescriptions: { [key: string]: string } = {
        'Administrador': 'Tu rol tiene acceso completo al sistema. Puedes crear, editar y eliminar usuarios, además de acceder a toda la información disponible.',
        'Ciudadano': 'Tu rol te permite visualizar los datos cargados y descargar la documentación relacionada.',
        'Técnico': 'Tu rol te permite visualizar los datos cargados, descargar la data y descargar la documentación disponible.',
        'Tomador de Decisiones': 'Tu rol te permite visualizar los datos cargados, descargar la data, cargar nueva data y descargar la documentación disponible.'
    };
    const roleDescription = user?.features.rol ? roleDescriptions[user.features.rol] : 'Rol no especificado o sin descripción disponible.';
    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Hola, {user?.username}</h1>
                </div>
            </div>
            <div className="flex justify-center space-x-6 my-8">
                <div className="w-32 h-32 rounded-full bg-cience2/90 flex items-center justify-center text-center text-sm font-semibold text-blue-800">
                    {user?.features.entity || 'Institución'}
                </div>
                <div className="w-32 h-32 rounded-full bg-primary/90 flex items-center justify-center text-center text-sm font-semibold text-green-800">
                    {user?.features.rol || 'Rol'}
                </div>
                {user?.features.rol === 'Técnico' && (
                    <>
                    <div className="w-32 h-32 rounded-full bg-analist/90 flex items-center justify-center text-center text-sm font-semibold text-orange-800">
                        {user.features.work || 'Area de trabajo'}
                    </div>
                    <div className="w-32 h-32 rounded-full bg-yellow1/90 flex items-center justify-center text-center text-sm font-semibold text-yellow-800">
                        {user.features.location || 'Municipio'}
                    </div>
                    </>
                )}
            </div>
            <div className="p-2 sm:p-4 text-center text-base md:text-lg max-w-screen-lg mx-auto">
                <p className='border border-primary rounded-md p-4'>
                    {roleDescription}
                </p>
            </div>
        </main>
    );
};

export default PerfilPague;
