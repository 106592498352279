import React, { useState } from 'react';

const terms = [
    {
        title: "Calidad del aire",
        definition: "La calidad del aire trata de la composición del aire y de su idoneidad para determinadas aplicaciones."
    },
    {
        title: "Contaminante atmosférico",
        definition: "Es la presencia de sustancias en la atmósfera en altas concentraciones, durante un tiempo determinado, como resultado de actividades humanas o procesos naturales, que pueden ocasionar daños a la salud de las personas o al ambiente."
    },
    {
        title: "Contaminantes criterio",
        definition: "Contaminantes trazadores que permiten establecer el efecto de la contaminación del aire en la salud (PM10, PM2.5 y O3)."
    },
    {
        title: "Evento agudo",
        definition: "Enfermedad que tiene un curso corto y relativamente severo. Referencia: National Library of Medicine",
        link:'https://www.ncbi.nlm.nih.gov/mesh/68000208'
    },
    {
        title: "Evento crónico",
        definition: "Enfermedades que tienen una o más de las siguientes características: son permanentes, dejan incapacidad residual, son causadas por alteración patológica irreversible, requieren entrenamiento especial del paciente para su rehabilitación, o se puede esperar que requieran un largo período de supervisión, observación o cuidado. Referencia: National Library of Medicine",
        link: 'https://www.ncbi.nlm.nih.gov/mesh/68002908'
    },
    {
        title: "Episodio atmosférico",
        definition: "Momento en el cual existe un desbordamiento de los valores límite en las concentraciones de los contaminantes, afectando la salud de quienes se exponen a dichas concentraciones. En este estudio, se considera episodio de contaminación atmosférica aquel período de tres o más días consecutivos en los que las concentraciones diarias de PM10, PM2.5 y O3 exceden el umbral de tres veces los valores límite recomendados por la OMS."
    },
    {
        title: "Exposición a corto plazo",
        definition: "Cambios en las concentraciones diarias de contaminantes a partir de una línea de base específica."
    },
    {
        title: "Exposición a largo plazo",
        definition: "Cambios en las concentraciones mensuales o anuales a partir de una línea de base específica."
    },
    {
        title: "Factores de riesgo / protectores",
        definition: "Atributos, variables o circunstancias inherentes o no a los individuos que están relacionados con fenómenos de salud, y que determinan en la población expuesta una mayor o menor probabilidad de ocurrencia de un evento de salud."
    },
    {
        title: "Historia natural de la enfermedad",
        definition: "Curso de acontecimientos biológicos entre la acción secuencial de las causas (etiología), el desarrollo de la enfermedad y el desenlace (curación, paso a la cronicidad, discapacidad o muerte). Incluye los períodos pre patogénico y patogénico, correspondientes a las manifestaciones de la enfermedad."
    },
    {
        title: "Material particulado",
        definition: "Conjunto de partículas sólidas y/o líquidas (a excepción del agua pura) presentes en suspensión en la atmósfera, originadas a partir de diversas fuentes naturales o antropogénicas."
    },
    {
        title: "Medidas sanitarias",
        definition: "Estrategias de salud pública y otras precauciones aplicadas por la autoridad sanitaria para prevenir, mitigar, controlar o eliminar la propagación de un evento que afecte o pueda afectar la salud de la población."
    },
    {
        title: "Morbilidad",
        definition: "Término médico y epidemiológico que se refiere a la cantidad de personas enfermas o víctimas de una enfermedad en un espacio y tiempo determinados. La morbilidad se expresa cuantitativamente, y su análisis permite valorar el aumento o disminución de casos/frecuencias/tasas de enfermedades en un espacio específico."
    },
    {
        title: "Mortalidad",
        definition: "Expresión demográfica que designa el número de defunciones ocurridas en una población, en un tiempo y lugar específicos. Es un indicador que permite valorar la situación de salud de las poblaciones y, junto con la morbilidad, apoya la planificación y gestión de políticas públicas sanitarias."
    },
    {
        title: "Ozono",
        definition: "Gas azul pálido que, en las capas bajas de la atmósfera, se origina por reacciones entre los óxidos de nitrógeno y los hidrocarburos (compuestos principalmente de carbono e hidrógeno) en presencia de la luz solar."
    },
    {
        title: "Porcentaje",
        definition: "Relación que se establece entre cada una de las partes de un todo."
    },
    {
        title: "Protocolo de vigilancia en salud pública",
        definition: "Guía técnica y operativa que estandariza los criterios, procedimientos y actividades que permiten sistematizar la vigilancia de eventos de interés en salud pública."
    },
    {
        title: "Red de monitoreo",
        definition: "Constituida por equipos especializados dirigidos a realizar el seguimiento de las concentraciones de contaminantes en puntos representativos de diferentes entornos del Área Metropolitana. La información se analiza junto con los fenómenos de dispersión, transporte de contaminantes y distribución de emisiones."
    },
    {
        title: "Rezago",
        definition: "Días o conjunto de días en los que se presenta el evento de salud en relación al momento en el cual se produce un aumento en las concentraciones del contaminante analizado."
    },
    {
        title: "Rezago simple",
        definition: "Día en que se presenta la enfermedad en relación al momento de incremento en las concentraciones del contaminante."
    },
    {
        title: "Rezago acumulado",
        definition: "Conjunto de días en que se presenta la enfermedad en relación al momento de aumento en las concentraciones del contaminante."
    },
    {
        title: "Serie de tiempo",
        definition: "Secuencia de observaciones de un fenómeno determinado, ordenadas temporalmente y adquiridas en igual intervalo de tiempo."
    },
    {
        title: "Sistema de vigilancia en salud pública",
        definition: "Conjunto de usuarios, normas, procedimientos, recursos técnicos, financieros y de talento humano, organizados para la recopilación, análisis, interpretación, actualización, divulgación y evaluación de la información sobre eventos en salud."
    },
    {
        title: "Tasa de mortalidad",
        definition: "Número de muertes por cada 1.000 habitantes durante un período determinado."
    },
    {
        title: "Tendencia",
        definition: "Componente de la serie de tiempo que describe el movimiento general y persistente de los valores durante un número prolongado de períodos (generalmente años). Puede ser creciente o decreciente, lineal o no lineal."
    },
    {
        title: "Vigilancia en salud ambiental",
        definition: "Procesos y componentes que permiten la recolección, análisis e interpretación de información de salud y ambiente. Incluye datos de morbilidad y mortalidad de fuentes primarias o secundarias y monitoreos ambientales sobre calidad del aire, meteorología, clima y determinantes ambientales y sociales, con el fin de apoyar la planificación y la implementación de medidas de prevención y mitigación contra factores contaminantes de origen antrópico."
    },
];


const Glossary: React.FC = () => {
    const [selectedLetter, setSelectedLetter] = useState<string | null>(null);

    const alphabet = 'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ'.split('');

    const filteredTerms = selectedLetter
        ? terms.filter(term => term.title.toUpperCase().startsWith(selectedLetter))
        : terms;

    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Glosario</h1>
                </div>
            </div>
            <div className="p-2 sm:p-4 mx-auto">
                <div className="flex flex-wrap justify-center mb-4">
                    {alphabet.map(letter => (
                        <button
                            key={letter}
                            onClick={() => setSelectedLetter(letter)}
                            className={`px-2 py-1 mx-1 sm:mt-1 mb-1 ${selectedLetter === letter ? 'bg-primary text-white' : 'bg-gris2'} rounded`}
                        >
                            {letter}
                        </button>
                    ))}
                    <button
                        onClick={() => setSelectedLetter(null)}
                        className="px-2 py-1 mx-1 bg-gray-200 rounded"
                    >
                        Todos
                    </button>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {filteredTerms.map((term, index) => (
                        <div key={index} className="p-4 border border-primary rounded-lg hover:bg-gris1 transition-colors">
                            <h3 className="text-lg font-bold text-primary mb-2">{term.title}</h3>
                            <p>
                                {term.definition}
                                {term.link && (
                                    <>
                                        <br />
                                        <a href={term.link} target="_blank" rel="noopener noreferrer" className="text-primary underline break-all">
                                            {term.link}
                                        </a>
                                    </>
                                )}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </main>
    );
};

export default Glossary;