import React from 'react';

const MainPague: React.FC = () => {
    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-2xl md:text-5xl font-bold">TE DAMOS LA BIENVENIDA A SIVISA</h1>
                    <p className="text-lg md:text-2xl">Sistema de Vigilancia en Salud Ambiental - Aire y Salud del Valle de Aburrá</p>
                </div>
            </div>

            <div className="p-4 sm:p-8 text-justify text-base md:text-lg max-w-screen-md mx-auto">
            <p>
            Desde 2016, el Área Metropolitana del Valle de Aburrá y la Facultad de Salud Pública de la Universidad de Antioquia
            han investigado cómo la contaminación del aire afecta la salud, especialmente en niños menores de 5 años y adultos 
            mayores de 64.
            </p>
            <br></br>
            <p>
            Estos estudios han generado protocolos y herramientas para el Sistema de Vigilancia en Salud Ambiental (SIVISA), 
            que busca aportar información que ayude a reducir las enfermedades y muertes relacionadas con la contaminación del aire. 
            Este sistema forma parte del Plan de Gestión de la Calidad del Aire (PIGECA), y proporciona información clave para la ciudadanía 
            y los tomadores de decisiones en los 10 municipios de la región.        
            </p>
            <br></br>
            <p className='font-bold text-black text-center'>
            ⚠️La información y los análisis estadisticos contenidos en este portal están en proceso de actualización y mejora, 
            disculpe las molestias.⚠️
            </p> 
            </div>
        </main>
    );
};

export default MainPague;
