import { ValidUser } from "./login";

export const validUsers: ValidUser[] = [
    {
        username: 'Dayana Jiménez',
        password: '123456',
        features: {
            entity: 'Universidad de Antioquia',
            rol: 'Tomador de Decisiones',
            work: 'Analista de dato',
            email: 'dayana.jimenez@udea.edu.co',
        }
    },
    {
        username: 'Fernan Alonso Villa Garzón',
        password: '123456',
        features: {
            entity: 'Universidad Nacional de Colombia',
            rol: 'Tomador de Decisiones',
            work: 'Jefe de proyecto',
            email: 'favillao@unal.edu.co',
        }
    },
    {
        username: 'Maria Alejandra Muñoz Alarcón',
        password: '123456',
        features: {
            entity: 'Universidad Nacional de Colombia Sede Medellín',
            rol: 'Tomador de Decisiones',
            work: 'Analista de datos',
            email: 'alejandra@gmail.com',
        }
    },
    {
        username: 'Maria Alejandra Muñoz Alarcón',
        password: '123456',
        features: {
            entity: 'Universidad Nacional de Colombia Sede Medellín',
            rol: 'Técnico',
            work: 'Analista de datos',
            email: 'alejandra161907@gmail.com',
        }
    },
    {
        username: 'Nora Adriana Montealegre Hernández',
        password: '123456',
        features: {
            entity: 'Universidad de Antioquia',
            rol: 'Técnico',
            work: 'Técnico analista de datos',
            location:'Medellin',
            email: 'nora.montealegre@udea.edu.co',
        }
    },
    {
        username: 'Universidad Nacional de Colombia Sede Medellín 2024',
        password: '123456',
        features: {
            entity: 'Universidad Nacional de Colombia Sede Medellín',
            rol: 'Administrador',
            work: 'Administrador',
            email: 'unal@gmail.com',
        }
    },
    {
        username: 'Universidad de Antioquia',
        password: '123456',
        features: {
            entity: 'Universidad de Antioquia',
            rol: 'Administrador',
            work: 'Administrador',
            email: 'udea@udea.edu.co',
        }
    },
    {
        username: 'Usuario Técnico Universidad de Antioquia',
        password: '123456',
        features: {
            entity: 'Universidad de Antioquia',
            rol: 'Técnico',
            work: 'Analista de datos',
            location:'Area Metropolitana',
            email: 'sivisa.tecnico@sivisa.org',
        }
    },
//     {
//     "username": "Ana Lucia Moreno",
//     "password": "1049628702",
//     "features": {
//         "entity": "Municipio de Barbosa",
//         "rol": "Técnico",
//         "work": "Secretaria de Ambiente",
//         "location": "Barbosa",
//         "email": "ana.moreno@barbosa.gov.co"
//     }
// },
// {
//     "username": "Sebastian Vasquez",
//     "password": "1039451066",
//     "features": {
//         "entity": "Municipio de Barbosa",
//         "rol": "Técnico",
//         "work": "Secretaria de Ambiente",
//         "location": "Barbosa",
//         "email": "sebastian.vasquez@barbosa.gov.co"
//     }
// },
// {
//     "username": "Jesus A Medina",
//     "password": "7678529",
//     "features": {
//         "entity": "Municipio de Bello",
//         "rol": "Técnico",
//         "work": "Secretaria de Ambiente",
//         "location": "Bello",
//         "email": "jesus.medina@bello.gov.co"
//     }
// },
// {
//     "username": "Juan Gabriel Rodríguez",
//     "password": "74814813",
//     "features": {
//         "entity": "Municipio de Bello",
//         "rol": "Técnico",
//         "work": "Secretaria de Planeación",
//         "location": "Bello",
//         "email": "juang.rodriguez@bello.gov.co"
//     }
// },
// {
//     "username": "Eliana Marcela Ramirez Amaya",
//     "password": "1035420699",
//     "features": {
//         "entity": "Municipio de Copacabana",
//         "rol": "Técnico",
//         "work": "Secretaria de Ambiente",
//         "location": "Copacabana",
//         "email": "agroambiental@copacabana.gov.co"
//     }
// },
// {
//     "username": "Margarita Villegas",
//     "password": "43868060",
//     "features": {
//         "entity": "Municipio de Envigado",
//         "rol": "Técnico",
//         "work": "Secretaria de Salud",
//         "location": "Envigado",
//         "email": "margarita.villegas@envigado.gov.co"
//     }
// },
// {
//     "username": "Walter Bolivar",
//     "password": "98658756",
//     "features": {
//         "entity": "Municipio de Envigado",
//         "rol": "Técnico",
//         "work": "Secretaria de Salud",
//         "location": "Envigado",
//         "email": "walter.bolivar@envigado.gov.co"
//     }
// },
// {
//     "username": "Ana Catalina Posada",
//     "password": "43755104",
//     "features": {
//         "entity": "Municipio de Envigado",
//         "rol": "Técnico",
//         "work": "Secretaria de Salud",
//         "location": "Envigado",
//         "email": "ana.posada@envigado.gov.co"
//     }
// },
// {
//     "username": "Alejandro Arcila Mesa",
//     "password": "1037624204",
//     "features": {
//         "entity": "Municipio de Envigado",
//         "rol": "Técnico",
//         "work": "Secretaria de Salud",
//         "location": "Envigado",
//         "email": "alejandro.arcila@envigado.gov.co"
//     }
// },
// {
//     "username": "Julian David Lopez Agudelo",
//     "password": "1037576269",
//     "features": {
//         "entity": "Municipio de Envigado",
//         "rol": "Técnico",
//         "work": "Secretaria de Salud",
//         "location": "Envigado",
//         "email": "julian.lopez@envigado.gov.co"
//     }
// },
// {
//     "username": "Ana Maria Montoya Velazquez",
//     "password": "32243331",
//     "features": {
//         "entity": "Municipio de Envigado",
//         "rol": "Técnico",
//         "work": "Secretaria de Salud",
//         "location": "Envigado",
//         "email": "ana.montoya@envigado.gov.co"
//     }
// },
// {
//     "username": "Marcela Agudelo",
//     "password": "32242761",
//     "features": {
//         "entity": "Municipio de Envigado",
//         "rol": "Técnico",
//         "work": "Secretaria de Ambiente",
//         "location": "Envigado",
//         "email": "carlota.agudelo@envigado.gov.co"
//     }
// },
// {
//     "username": "Andres Mejia",
//     "password": "3391758",
//     "features": {
//         "entity": "Municipio de Envigado",
//         "rol": "Técnico",
//         "work": "Secretaria de Ambiente",
//         "location": "Envigado",
//         "email": "andres.mejia@envigado.gov.co"
//     }
// },
// {
//     "username": "Yeison Rendon Guerra",
//     "password": "71278657",
//     "features": {
//         "entity": "Municipio de Envigado",
//         "rol": "Técnico",
//         "work": "Secretaria de Movilidad",
//         "location": "Envigado",
//         "email": "yeison.rendon@envigado.gov.co"
//     }
// },
// {
//     "username": "Camen C. Lopez",
//     "password": "42883616",
//     "features": {
//         "entity": "Municipio de Envigado",
//         "rol": "Técnico",
//         "work": "Secretaria de Gestion del riesgo",
//         "location": "Envigado",
//         "email": "carmen.lopez@envigado.gov.co"
//     }
// },
// {
//     "username": "Diana Carolina Estrada",
//     "password": "1017191964",
//     "features": {
//         "entity": "Municipio de Girardota",
//         "rol": "Técnico",
//         "work": "Secretaria de Salud",
//         "location": "Girardota",
//         "email": "epidemiologia@girardota.gov.co"
//     }
// },
// {
//     "username": "Sebastián Saldarriaga Bedoya",
//     "password": "1035871482",
//     "features": {
//         "entity": "Municipio de Girardota",
//         "rol": "Técnico",
//         "work": "Secretaria de Ambiente",
//         "location": "Girardota",
//         "email": "ingeniero.ambiente@girardota.gov.co"
//     }
// },
// {
//     "username": "Cristina Isabel Lemos",
//     "password": "1128429922",
//     "features": {
//         "entity": "Municipio de Girardota",
//         "rol": "Técnico",
//         "work": "Secretaria de Ambiente",
//         "location": "Girardota",
//         "email": "medio.ambiente@girardota.gov.co"
//     }
// },

// {
//     "username": "Fabio Castrillon",
//     "password": "70519992",
//     "features": {
//         "entity": "Municipio de Itagüí",
//         "rol": "Técnico",
//         "work": "Secretaria de Movilidad",
//         "location": "Itagüí",
//         "email": "Fabio.castrillon@itagui.gov.co"
//     }
// },
// {
//     "username": "Martha Isabel Zapata",
//     "password": "1020413329",
//     "features": {
//         "entity": "Municipio de La estrella",
//         "rol": "Técnico",
//         "work": "Secretaria de Salud",
//         "location": "La estrella",
//         "email": "epidemiologia@laestrella.gov.co"
//     }
// },
// {
//     "username": "Fabio Alberto Madrid",
//     "password": "70569248",
//     "features": {
//         "entity": "Municipio de La estrella",
//         "rol": "Técnico",
//         "work": "Secretaria de Ambiente",
//         "location": "La estrella",
//         "email": "fabio.madrid@laestrella.gov.co"
//     }
// },
// {
//     "username": "Julian Alejandro Valencia",
//     "password": "1037595255",
//     "features": {
//         "entity": "Municipio de La estrella",
//         "rol": "Técnico",
//         "work": "Secretaria de Gestion del riesgo",
//         "location": "La estrella",
//         "email": "gestionderiesgo@laestrella.gov.co"
//     }
// },
// {
//     "username": "Wilton Alberto Mazo",
//     "password": "98565177",
//     "features": {
//         "entity": "Municipio de Sabaneta",
//         "rol": "Técnico",
//         "work": "Secretaria de Salud",
//         "location": "Sabaneta",
//         "email": "vigilanciaycontrol@sabaneta.gov.co"
//     }
// },
// {
//     "username": "Juan Pablo Bustamante",
//     "password": "1039462477",
//     "features": {
//         "entity": "Municipio de Sabaneta",
//         "rol": "Técnico",
//         "work": "Secretaria de Salud",
//         "location": "Sabaneta",
//         "email": "salud.cont19@sabaneta.gov.co"
//     }
// },
// {
//     "username": "Marisol Patiño",
//     "password": "43901671",
//     "features": {
//         "entity": "Municipio de Sabaneta",
//         "rol": "Técnico",
//         "work": "Secretaria de Salud",
//         "location": "Sabaneta",
//         "email": "salud.cont3@sabaneta.gov.co"
//     }
// },
// {
//     "username": "Maria Alejandra R",
//     "password": "1214724990",
//     "features": {
//         "entity": "Municipio de Sabaneta",
//         "rol": "Técnico",
//         "work": "Secretaria de Ambiente",
//         "location": "Sabaneta",
//         "email": "secre.ambiente2@sabaneta.gov.co"
//     }
// },
// {
//     "username": "Fadher E. Gonzalez",
//     "password": "91536153",
//     "features": {
//         "entity": "Municipio de Sabaneta",
//         "rol": "Técnico",
//         "work": "Secretaria de Planeación",
//         "location": "Sabaneta",
//         "email": "proplaneacion@sabaneta.gov.co"
//     }
// },
// {
//     "username": "Gustavo Adolfo Sanchez Loaiza",
//     "password": "71214121",
//     "features": {
//         "entity": "Municipio de Sabaneta",
//         "rol": "Técnico",
//         "work": "Secretaria de Gobierno",
//         "location": "Sabaneta",
//         "email": "seguridad.c3@sabaneta.gov.co"
//     }
// },
{
    "username": "Francisco Pulgarin Hernandez",
    "password": "71766523",
    "features": {
        "entity": "Municipio de Amva",
        "rol": "Técnico",
        "work": "Secretaria de Salud",
        "location": "Amva",
        "email": "francisco.pulgarin@metropol.gov.co"
    }
},
{
    "username": "Carolina Pérez Zapata",
    "password": "1036611275",
    "features": {
        "entity": "Municipio de Amva",
        "rol": "Técnico",
        "work": "Secretaria de Salud",
        "location": "Amva",
        "email": "carolina.perezz@metropol.gov.co"
    }
},
{
    "username": "María Isabel Muñoz Morales",
    "password": "42800129",
    "features": {
        "entity": "Municipio de Amva",
        "rol": "Técnico",
        "work": "Secretaria de Salud",
        "location": "Amva",
        "email": "maria.munoz@metropol.gov.co"
    }
},
{
    "username": "Ana Zuleima Orrego",
    "password": "43154065",
    "features": {
        "entity": "Municipio de Amva",
        "rol": "Técnico",
        "work": "Secretaria de Salud",
        "location": "Amva",
        "email": "ana.orrego@metropol.gov.co"
    }
},
{
    "username": "Lina Marcela Foronda",
    "password": "1035415965",
    "features": {
        "entity": "Alcaldía de Copacabana",
        "rol": "Técnico",
        "work": "Secretaria de Ambiente",
        "location": "Municipio de Copacabana",
        "email": "lina.foronda@copacabana.gov.co"
    }
},
{
    "username": "Julián Cano",
    "password": "8032554",
    "features": {
        "entity": "Alcaldía de Copacabana",
        "rol": "Técnico",
        "work": "Secretaria de Movilidad",
        "location": "Municipio de Copacabana",
        "email": "julian.cano@copacabana.gov.co"
    }
},
{
    "username": "Manuela Hernández",
    "password": "1193119502",
    "features": {
        "entity": "Alcaldía de Copacabana",
        "rol": "Técnico",
        "work": "Secretaria de Salud",
        "location": "Municipio de Copacabana",
        "email": "vigilanciaepidemiologica@copacabana.gov.co"
    }
},
{
    "username": "José Alexander Villa",
    "password": "15513606",
    "features": {
        "entity": "Alcaldía de Copacabana",
        "rol": "Técnico",
        "work": "Secretaria de Ambiente",
        "location": "Municipio de Copacabana",
        "email": "alexander.villa@copacabana.gov.co"
    }
},
{
    "username": "Yohana Marcela Alzate Tabares",
    "password": "1035859661",
    "features": {
        "entity": "Alcaldía de Copacabana",
        "rol": "Técnico",
        "work": "Secretaria de Salud",
        "location": "Municipio de Copacabana",
        "email": "yohana.alzate@copacabana.gov.co"
    }
},
{
    "username": "Yesenia Leandra Correa Valencia",
    "password": "1037391088",
    "features": {
        "entity": "Alcaldía de La Estrella",
        "rol": "Técnico",
        "work": "Secretaria de Salud",
        "location": "Municipio de La Estrella",
        "email": "leandra.correa@laestrella.gov.co"
    }
},
{
    "username": "Fabio Alberto Madrid",
    "password": "70569248",
    "features": {
        "entity": "Alcaldía de La Estrella",
        "rol": "Técnico",
        "work": "Secretaria de Ambiente",
        "location": "Municipio de La Estrella",
        "email": "fabio.madrid@laestrella.gov.co"
    }
},
{
    "username": "Maria Isabel Estrada",
    "password": "42887470",
    "features": {
        "entity": "Alcaldía de La Estrella",
        "rol": "Técnico",
        "work": "Secretaria de Ambiente",
        "location": "Municipio de La Estrella",
        "email": "maria.estrada@laestrella.gov.co"
    }
},
{
    "username": "Judith María Zapata",
    "password": "43833313",
    "features": {
        "entity": "Alcaldía de Itagüí",
        "rol": "Técnico",
        "work": "Secretaria de Salud",
        "location": "Municipio de Itagüí",
        "email": "judith.zapata@itagui.gov.co"
    }
},
{
    "username": "Jorge Luis Nieto",
    "password": "1143356153",
    "features": {
        "entity": "Alcaldía de Itagüí",
        "rol": "Técnico",
        "work": "Secretaria de Ambiente",
        "location": "Municipio de Itagüí",
        "email": "Jorge.nieto@itagui.go.co"
    }
},
{
    "username": "Victor Hugo Ossa Alvarez",
    "password": "3563627",
    "features": {
        "entity": "Alcaldía de Sabaneta",
        "rol": "Técnico",
        "work": "Secretaria de Salud",
        "location": "Municipio de Sabaneta",
        "email": "victor.ossa@sabaneta.gov.co"
    }
},
{
    "username": "Ana Maria Guerra Osorio",
    "password": "1102815548",
    "features": {
        "entity": "Alcaldía de Sabaneta",
        "rol": "Técnico",
        "work": "Secretaria de Salud",
        "location": "Municipio de Sabaneta",
        "email": "ana.guerra@sabaneta.gov.co"
    }
},
{
    "username": "Paola Andrea Rico Valencia",
    "password": "29760758",
    "features": {
        "entity": "Alcaldía de Sabaneta",
        "rol": "Técnico",
        "work": "Secretaria de Ambiente",
        "location": "Municipio de Sabaneta",
        "email": "paola.rico@sabaneta.gov.co"
    }
},
{
    "username": "Cindy Julieth Suaza Bejarano",
    "password": "1039451525",
    "features": {
        "entity": "Alcaldía de Sabaneta",
        "rol": "Técnico",
        "work": "Secretaria de Ambiente",
        "location": "Municipio de Sabaneta",
        "email": "cindy.suaza@sabaneta.gov.co"
    }
},
{
    "username": "Diana Lopera",
    "password": "4310794",
    "features": {
        "entity": "Alcaldía de Bello",
        "rol": "Técnico",
        "work": "Secretaria de Salud",
        "location": "Municipio de Bello",
        "email": "diana.Lopera@bello.gov.co"
    }
},
{
    "username": "Jesus A Medina",
    "password": "7678529",
    "features": {
        "entity": "Alcaldía de Bello",
        "rol": "Técnico",
        "work": "Secretaria de Ambiente",
        "location": "Municipio de Bello",
        "email": "jesus.medina@bello.gov.co"
    }
},
{
    "username": "Mariana Jiménez López",
    "password": "1026157713",
    "features": {
        "entity": "Alcaldía de Caldas",
        "rol": "Técnico",
        "work": "Secretaria de Salud",
        "location": "Municipio de Caldas",
        "email": "saneamiento@caldasantioquia.gov.co"
    }
},
{
    "username": "Juan Camilo Escobar",
    "password": "71295853",
    "features": {
        "entity": "Alcaldía de Caldas",
        "rol": "Técnico",
        "work": "Secretaria de Gestión del Riesgo",
        "location": "Municipio de Caldas",
        "email": "gestion.riesgo@caldasantioquia.gov.co"
    }
},
{
    "username": "Ruth Yael Sanchez",
    "password": "43918658",
    "features": {
        "entity": "Alcaldía de La Estrella",
        "rol": "Técnico",
        "work": "Secretaria de Salud",
        "location": "Municipio de La Estrella",
        "email": "sunidad@laestrella.gov.co"
    }
},
{
    "username": "Andrés Mauricio Felizzdo",
    "password": "1091660956",
    "features": {
        "entity": "Alcaldía de Itagüí",
        "rol": "Técnico",
        "work": "Secretaria de Planeación",
        "location": "Municipio de Itagüí",
        "email": "andres.felizzdo@itagui.gov.co"
    }
},
{
    "username": "Vanesa Toro cano",
    "password": "1039455332",
    "features": {
        "entity": "Alcaldía de Sabaneta",
        "rol": "Técnico",
        "work": "Secretaria de Salud",
        "location": "Municipio de Sabaneta",
        "email": "vanesa.toro@sabaneta.gov.co"
    }
},
{
    "username": "Norela Velez",
    "password": "43723664",
    "features": {
        "entity": "Alcaldía de Sabaneta",
        "rol": "Técnico",
        "work": "Secretaria de Salud",
        "location": "Municipio de Sabaneta",
        "email": "norela.velez@sabaneta.gov.co"
    }
},
]