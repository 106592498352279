import React from 'react';
import { Link } from 'react-router-dom';

const E2015: React.FC = () => {
    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Episodio 2015 para el municipio de Medellín</h1>
                    <br/>
                    <div>
                        <Link to="/analisis-atmosfericos/informacion" className=" px-4 py-2 hover:bg-primary rounded-md underline" >Volver</Link>
                    </div>
                </div>
            </div>
            <div className="p-4 sm:p-8 text-justify text-base md:text-lg max-w-screen-lg mx-auto">
                <div className="border border-primary rounded-md p-4">
                    <h2 className="font-bold text-lg text-center">¿Cómo se han comportado los casos de atención en salud y las defunciones durante los episodios de contaminación del aire en los municipios del Valle de Aburrá?</h2>
                    <p className="mt-2">
                    Los episodios de contaminación del aire, definidos por concentraciones de material particulado que exceden tres veces los niveles aceptados por la OMS, han sido registrados en los municipios del AMVA entre 2015 y 2019. Aquí puedes explorar estos episodios y su impacto en el aumento de defunciones y casos de atención en salud por enfermedades respiratorias y circulatorias agudas.                    </p>
                </div>
            </div>
            <h2 className="font-bold text-primary text-xl text-center mb-4">Análisis de episodios atmosféricos</h2>
            {/* Report 1 */}
            <div className="w-full h-screen mb-6">
                <iframe 
                    allowFullScreen={true} 
                    src="https://app.powerbi.com/view?r=eyJrIjoiZWU0OTIyYjItZmQwNi00ODQ0LWI0MTgtZWNjNWE4MDQxY2M0IiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9" 
                    title="Report Section" 
                    className="w-full h-full border border-gray-300 rounded-md"
                ></iframe>
            </div>
            {/* Report 2*/}
            <div className="w-full h-screen mb-6">
                <iframe 
                    allowFullScreen={true} 
                    src="https://app.powerbi.com/view?r=eyJrIjoiZjY3YTVmZDUtZDRkZi00NzY1LWI1YTItZWEzMjg5MTg4NjRmIiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9"
                    title="Report Section" 
                    className="w-full h-full border border-gray-300 rounded-md"
                ></iframe>
            </div>
            <h2 className="font-bold text-primary text-xl text-center mb-4">Análisis de efectos en salud por PM2.5</h2>
            {/* Report 2 */}
            <div className="w-full h-screen mb-6">
                <iframe 
                    allowFullScreen={true} 
                    src="https://app.powerbi.com/view?r=eyJrIjoiNWU5OTE3OGItODg1Yi00YTc4LTkyNjctMzFmMjRiYjAxNzM3IiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9"
                    title="Report Section" 
                    className="w-full h-full border border-gray-300 rounded-md"
                ></iframe>
            </div>
            <br/>
            <div className="w-full h-screen mb-6">
                <iframe 
                    allowFullScreen={true} 
                    src="https://app.powerbi.com/view?r=eyJrIjoiNjI4MjFhN2QtOTRjYi00NTI5LWE2YTQtMTRjODBjOGU2MDkyIiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9"
                    title="Report Section" 
                    className="w-full h-full border border-gray-300 rounded-md"
                ></iframe>
            </div>
            <br/>

            {/* Report 3 */}
            <div className="w-full h-screen mb-6">
            <h2 className="font-bold text-primary text-xl text-center mb-4">Análisis del Riesgo</h2>
                <iframe 
                    allowFullScreen={true} 
                    src="https://app.powerbi.com/view?r=eyJrIjoiMGIwNTZjNzQtZGExZi00Y2I5LWJlZTktYzRhYjRiOTA2NGIyIiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9"
                    title="Report Section" 
                    className="w-full h-full border border-gray-300 rounded-md"
                ></iframe>
            </div>
            <br/>
            <br/>
            <div className="flex items-center justify-center">
                <img src='/analisisRiesgo.png' alt="exceso de morbilidad" className="w-[80%]" />
            </div>
            <p className="text-center justify-center items-center ml-8 mr-8">Porcentaje de excesos en los riesgos de morbilidad durante los periodos de alerta roja y alerta naranja, Medellín, 2015</p>
            <div className='p-2 sm:p-8 text-justify text-base md: max-w-screen-xl mx-auto'>
                <div className='font-bold border border-primary rounded-md p-4'>
                    <p >La alta exposición a PM2.5 es nociva para la salud de la población. Las enfermedades respiratorias como neumonía, asma e infecciones respiratorias agudas se incrementan tanto en los días de alerta naranjas como en los días de alerta roja, particularmente en estos últimos.</p>
                </div>
            </div>
            <div className="p-2 sm:p-8 text-justify text-base md: max-w-screen-xl mx-auto">
            <h2 className="font-bold text-primary text-xl text-center mb-4">Análisis de eventos en salud por contaminantes</h2>
                <div className="border border-primary rounded-md p-4">
                    <p className="">
                    En las siguientes tablas los rezagos son pertinentes en cuanto que los efectos en salud derivados de la exposición a la contaminación no siempre se manifiestan el mismo día de la exposición; para ciertos casos, el evento en salud se mantiene latente y se manifiesta días después de la exposición, es decir se manifiesta con algunos días de rezago. En los rezagos puntuales se reportan los efectos de la exposición puntual a cierto número de días de rezagos; en los rezagos distribuidos se reportan los efectos de la exposición acumulada de cierto número de días.
                    En las tablas también se reportan los RR como medida de asociación entre la exposición a la contaminación y el evento en salud correspondiente, de modo que si es igual a uno (1) se infiere que no hay asociación, si es mayor que uno (1) se entiende que la exposición es un factor de riesgo y si es menor que uno (1) la exposición es factor protector; expresa que tantas veces es el riesgo de presentarse el evento en salud en el grupo expuesto al aumento de la contaminación comparado con el grupo no expuesto; su intervalo de confianza mide no sólo la variabilidad sino también su significancia, de modo que si dicho intervalo no contiene el uno (1) se tendría el 95% de confianza en el resultado de la asociación, de lo contrario el resultado podría deberse al azar. Los valores de %RR expresan los excesos en la frecuencia del evento en salud debido a la exposición del contaminante.
                    </p>
                </div>
            </div>
            <div className="w-full h-screen mb-6">
            <iframe 
                    allowFullScreen={true} 
                    src="https://app.powerbi.com/view?r=eyJrIjoiMWZmYmRiMzAtZjc3OS00NWFhLWJjMmItM2VkYzNmMzNmMjgxIiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9"
                    title="Report Section" 
                    className="w-full h-full border border-gray-300 rounded-md"
            ></iframe>
            </div>
            <div className="w-full h-screen mb-6">
            <iframe 
                allowFullScreen={true} 
                src="https://app.powerbi.com/view?r=eyJrIjoiYTE0NjRjMDUtZTZhMy00ZjNlLTg5YjctNGI4MTY2YTBiMjFhIiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9"
                title="Report Section" 
                className="w-full h-full border border-gray-300 rounded-md"
            ></iframe>
            </div>
        </main>
    );
};

export default E2015;
